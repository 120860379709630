import * as React from 'react';
import { graphql } from 'gatsby';

import { 
  MainLayout, 
  Seo, 
  DailyIcon, 
  PostList,
  ActivitiesBox
} from '../components';

const ArticlesPage = ({ data, location }) => {

  const pageTitle = 'Articole';
  const posts = data.allMarkdownRemark.nodes;

  return (
    <>
      <Seo title={pageTitle} />
      <MainLayout title={pageTitle}>
        <article>
          <h1>{pageTitle}</h1>
          <PostList posts={posts} />
        </article>
        <aside>
          <DailyIcon />
          <ActivitiesBox />
        </aside>
      </MainLayout>
    </>
  );
};

export default ArticlesPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {fields: {slug: {regex: "^/articole/"}}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "D MMMM YYYY", locale: "ro")
          description
        }
        excerpt
      }
    }  
  }
`;
